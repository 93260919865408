import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import "../../../styles/portfolio.scss";
import bmclogo from "../../../images/logo/04 c.png";
import { gsap } from "gsap";

export default function BIPortfolio() {
  var colors = ["#f4f4f4"];

  const pics = [
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/01.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/02.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/03.jpg" },
    // { img: "https://blackelephantdigital.com/assets/portfolio/bmc/04.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/05.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/06.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/07.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/08.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/09.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/10.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/11.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/12.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/13.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/14.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/15.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/16.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/17.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/18.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/19.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/20.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/21.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/22.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/23.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/24.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/25.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/26.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/27.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/28.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/29.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/30.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/31.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/32.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/33.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/34.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/35.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/36.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/37.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/38.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/39.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/40.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/41.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/42.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/43.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/44.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/45.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/46.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/47.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/48.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/49.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/50.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/51.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/52.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/53.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/54.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/55.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/56.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/57.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/58.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/59.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/60.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/61.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/62.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/63.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/64.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/65.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/66.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/67.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/68.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/69.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/70.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/71.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/72.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/73.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/74.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/75.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/76.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/77.jpg" },
    { img: "https://blackelephantdigital.com/assets/portfolio/bmc/78.jpg" },
  ];

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  shuffle(pics);

  let col1 = [],
    col2 = [],
    col3 = [];

  for (let i = 0; i < pics.length; i++) {
    if ((i + 1) % 3 === 0) {
      col1.push(pics[i]);
    } else if ((i + 1) % 2 === 0) {
      col2.push(pics[i]);
    } else {
      col3.push(pics[i]);
    }
  }

  console.log(col1);
  console.log(col2);
  console.log(col3);
  const [updateComponent, setUpdateComponent] = useState(0);

  function handleClick() {}

  useEffect(() => {
    var w = window.innerWidth;

    let animboxes = gsap.set(".box", {
      backgroundColor: (i) => colors[Math.floor(Math.random() * 8)],
      y: (i) => (i * w) / 4,
    });

    let anim1 = gsap.to(".wrapper1 .box", {
      duration: 49,
      ease: "none",
      y: "+=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize(
          (y) => parseFloat(y) % ((w / 4) * col1.length + 1)
        ), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
    });
    let anim2 = gsap.to(".wrapper2 .box", {
      duration: 51,
      ease: "none",
      y: "-=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize(
          (y) => parseFloat(y) % ((w / 4) * col2.length + 1)
        ), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
    });
    let anim3 = gsap.to(".wrapper3 .box", {
      duration: 48,
      ease: "none",
      y: "+=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize(
          (y) => parseFloat(y) % ((w / 4) * col3.length + 1)
        ), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
    });

    window.addEventListener("resize", () => {
      anim1.kill();
      anim2.kill();
      anim3.kill();

      animboxes.kill();

      const boxes = document.querySelectorAll(".box");

      boxes.forEach((item) => {
        item.style = "";
      });
      setUpdateComponent(updateComponent + 1);
      w = window.innerWidth;
      animboxes = gsap.set(".box", {
        backgroundColor: (i) => colors[(i % colors.length) + 1],
        y: (i) => (i * w) / 4,
      });

      anim1 = gsap.to(".wrapper1 .box", {
        duration: 49,
        ease: "none",
        y: "+=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize(
            (y) => parseFloat(y) % ((w / 4) * col1.length + 1)
          ), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
      });
      anim2 = gsap.to(".wrapper2 .box", {
        duration: 51,
        ease: "none",
        y: "-=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize(
            (y) => parseFloat(y) % ((w / 4) * col1.length + 1)
          ), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
      });
      anim3 = gsap.to(".wrapper3 .box", {
        duration: 48,
        ease: "none",
        y: "+=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize(
            (y) => parseFloat(y) % ((w / 4) * col3.length + 1)
          ), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
      });
    });
  }, []);

  return (
    <Layout>
      <div className="portfolio">
        <div
          style={{ backgroundColor: "#000" }}
          className="portfolio__container"
        >
          <div className="portfolio__client">
            <div
              className="portfolio__client__logo"
              // style={{ backgroundColor: "#082D51" }}
            >
              <img className="" src={bmclogo}></img>
              {/* <div>Jaycee Power</div> */}
            </div>
          </div>

          <div className="portfolio__wrappers">
            <div class="wrapper wrapper1">
              <div class="boxes">
                {col1.map((item, i) => (
                  <div className="box" key={`col1-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
            <div class="wrapper wrapper2">
              <div class="boxes">
                {col2.map((item, i) => (
                  <div className="box" key={`col2-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
            <div class="wrapper wrapper3">
              <div class="boxes">
                {col3.map((item, i) => (
                  <div className="box" key={`col3-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
